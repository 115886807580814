import axios from "axios";

const formatDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = `0${d.getMonth()}`.slice(-2);
  const day = `0${d.getDay() + 1}`.slice(-2);
  return `${year}-${month}-${day}`;
};

const ListJadwalSholat = async (currentDate) => {
  // let url = `https://api.myquran.com/v1/sholat/jadwal/1225/${currentDate}`;
  // let url = `https://raw.githubusercontent.com/lakuapik/jadwalsholatorg/master/adzan/depok/${formattedDate}.json`;
  // let url = `https://api.aladhan.com/v1/timingsByCity/${currentDate}?city=Depok&country=Jakarta&method=8`;
  // let url = `https://api.aladhan.com/v1/timingsByAddress/${formatDate}?address=Jl.+Boulevard+Grand+Depok+City,+Kota+Depok,+Jawa+Barat`;
  const formattedDate = formatDate(currentDate);
  console.log(formattedDate);
  console.log("CURREN DATE", currentDate);

  let url = `https://api.myquran.com/v2/sholat/jadwal/1225/${currentDate}`;

  let res = await axios.get(url);
  return res;
};

const ConvertTanggalHijriyah = async (currentDate) => {
  const options = {
    method: "GET",
    url: `https://api.aladhan.com/v1/gToH/${currentDate}`,
  };

  let res = await axios.request(options);
  return res;
};

const randomSource = async () => {
  let url = `https://hadis-api-id.vercel.app/hadith`;
  let res = await axios.get(url);
  return res;
};

const randomHadits = async (slug, number) => {
  let url = `https://hadis-api-id.vercel.app/hadith/${slug}/${number}`;
  let res = await axios.get(url);
  return res;
};

export default {
  ListJadwalSholat,
  ConvertTanggalHijriyah,
  randomSource,
  randomHadits,
};
